import { LogMessageType } from "../react/misc/messages";
import { GAIN_TIME_CONST, SourceType } from "../components/audio-params";

function isThreeAudio(node) {
    return node instanceof THREE.Audio || node instanceof THREE.PositionalAudio;
}

export class AudioSystem {
    constructor(sceneEl) {
        this._sceneEl = sceneEl;

        this.audioContext = THREE.AudioContext.getContext();
        this.audioNodes = new Map();
        this.mediaStreamDestinationNode = this.audioContext.createMediaStreamDestination(); // Voice, camera, screenshare
        this.audioDestination = this.audioContext.createMediaStreamDestination(); // Media elements
        this.outboundStream = this.mediaStreamDestinationNode.stream;
        this.outboundGainNode = this.audioContext.createGain();
        this.outboundAnalyser = this.audioContext.createAnalyser();
        this.outboundAnalyser.fftSize = 32;
        this.analyserLevels = new Uint8Array(this.outboundAnalyser.fftSize);
        this.outboundGainNode.connect(this.outboundAnalyser);
        this.outboundAnalyser.connect(this.mediaStreamDestinationNode);
        this.audioContextNeedsToBeResumed = false;

        this.mixer = {
            [SourceType.AVATAR_AUDIO_SOURCE]: this.audioContext.createGain(),
            [SourceType.MEDIA_VIDEO]: this.audioContext.createGain(),
            [SourceType.MEDIA_AUDIO]: this.audioContext.createGain(),
            [SourceType.AUDIO_ZONE]: this.audioContext.createGain(),
            [SourceType.SFX]: this.audioContext.createGain()
        };
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].connect(this._sceneEl.audioListener.getInput());
        this.mixer[SourceType.MEDIA_VIDEO].connect(this._sceneEl.audioListener.getInput());
        this.mixer[SourceType.MEDIA_AUDIO].connect(this._sceneEl.audioListener.getInput());
        this.mixer[SourceType.AUDIO_ZONE].connect(this._sceneEl.audioListener.getInput());
        this.mixer[SourceType.SFX].connect(this._sceneEl.audioListener.getInput());

        // Analyser to show the output audio level
        this.mixerAnalyser = this.audioContext.createAnalyser();
        this.mixerAnalyser.fftSize = 32;
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].connect(this.mixerAnalyser);
        this.mixer[SourceType.MEDIA_VIDEO].connect(this.mixerAnalyser);
        this.mixer[SourceType.MEDIA_AUDIO].connect(this.mixerAnalyser);
        this.mixer[SourceType.AUDIO_ZONE].connect(this.mixerAnalyser);
        this.mixer[SourceType.SFX].connect(this.mixerAnalyser);

        // Webkit Mobile fix
        this._safariMobileAudioInterruptionFix();

        document.body.addEventListener("touchend", this._resumeAudioContext, false);
        document.body.addEventListener("mouseup", this._resumeAudioContext, false);

        this.onPrefsUpdated = this.updatePrefs.bind(this);
        window.APP.store.addEventListener("statechanged", this.onPrefsUpdated);

        this.onSinkUpdated = this.updateSink.bind(this);
        window.APP.store.addEventListener("sinkchanged", this.onSinkUpdated);
    }

    addStreamToOutboundAudio(id, mediaStream) {
        if (this.audioNodes.has(id)) {
            this.removeStreamFromOutboundAudio(id);
        }

        const sourceNode = this.audioContext.createMediaStreamSource(mediaStream);
        const gainNode = this.audioContext.createGain();
        sourceNode.connect(gainNode);
        gainNode.connect(this.outboundGainNode);
        this.audioNodes.set(id, { sourceNode, gainNode });
    }

    removeStreamFromOutboundAudio(id) {
        if (this.audioNodes.has(id)) {
            const nodes = this.audioNodes.get(id);
            nodes.sourceNode.disconnect();
            nodes.gainNode.disconnect();
            this.audioNodes.delete(id);
        }
    }

    addAudio({ sourceType, node }) {
        let outputNode = node;
        if (isThreeAudio(node)) {
            node.gain.disconnect();
            outputNode = node.gain;
        }
        outputNode.connect(this.mixer[sourceType]);
    }

    removeAudio({ node }) {
        let outputNode = node;
        if (isThreeAudio(node)) {
            outputNode = node.gain;
        }
        outputNode.disconnect();
    }

    updateSink() {
        const { preferredSpeakers } = window.APP.store.state.preferences;

        const sinkId = preferredSpeakers;
        const isDefault = sinkId === APP.defaultOutputDeviceId;
        if ((!this.outputMediaAudio && isDefault) || sinkId === this.outputMediaAudio?.sinkId) return;
        const sink = isDefault ? this._sceneEl.audioListener.getInput() : this.audioDestination;
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].disconnect();
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].connect(sink);
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].connect(this.mixerAnalyser);

        this.mixer[SourceType.MEDIA_VIDEO].disconnect();
        this.mixer[SourceType.MEDIA_VIDEO].connect(sink);
        this.mixer[SourceType.MEDIA_VIDEO].connect(this.mixerAnalyser);

        this.mixer[SourceType.AUDIO_ZONE].disconnect();
        this.mixer[SourceType.AUDIO_ZONE].connect(sink);
        this.mixer[SourceType.AUDIO_ZONE].connect(this.mixerAnalyser);

        this.mixer[SourceType.MEDIA_AUDIO].disconnect();
        this.mixer[SourceType.MEDIA_AUDIO].connect(sink);
        this.mixer[SourceType.MEDIA_AUDIO].connect(this.mixerAnalyser);

        this.mixer[SourceType.SFX].disconnect();
        this.mixer[SourceType.SFX].connect(sink);
        this.mixer[SourceType.SFX].connect(this.mixerAnalyser);

        if (isDefault) {
            if (this.outputMediaAudio) {
                this.outputMediaAudio.pause();
                this.outputMediaAudio.srcObject = null;
                this.outputMediaAudio = null;
            }
        } else {
            // Swithing the audio sync is only supported in Chrome at the time of writing this.
            // It also seems to have some limitations and it only works on audio elements. We are piping all our media through the Audio Context
            // and that doesn't seem to work.
            // To workaround that we need to use a MediaStreamAudioDestinationNode that is set as the source of the audio element where we switch the sink.
            // This is very hacky but there don't seem to have any better alternatives at the time of writing this.
            // https://stackoverflow.com/a/67043782
            if (!this.outputMediaAudio) {
                this.outputMediaAudio = new Audio();
                this.outputMediaAudio.srcObject = this.audioDestination.stream;
            }
            if (this.outputMediaAudio.sinkId !== sinkId) {
                this.outputMediaAudio.setSinkId(sinkId).then(() => {
                    this.outputMediaAudio.play();
                });
            }
        }
    }

    updatePrefs() {
        const { globalVoiceVolume, globalMediaVolume, globalSFXVolume, preferredSpeakers } =
            window.APP.store.state.preferences;

        let newGain = globalMediaVolume / 100;

        this.mixer[SourceType.MEDIA_VIDEO].gain.setTargetAtTime(
            newGain,
            this.audioContext.currentTime,
            GAIN_TIME_CONST
        );

        this.mixer[SourceType.MEDIA_AUDIO].gain.setTargetAtTime(
            newGain,
            this.audioContext.currentTime,
            GAIN_TIME_CONST
        );

        this.mixer[SourceType.AUDIO_ZONE].gain.setTargetAtTime(newGain, this.audioContext.currentTime, GAIN_TIME_CONST);

        newGain = globalSFXVolume / 100;
        this.mixer[SourceType.SFX].gain.setTargetAtTime(newGain, this.audioContext.currentTime, GAIN_TIME_CONST);

        newGain = globalVoiceVolume / 100;
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].gain.setTargetAtTime(
            newGain,
            this.audioContext.currentTime,
            GAIN_TIME_CONST
        );

        /*
        const sinkId = preferredSpeakers;
        const isDefault = sinkId === APP.defaultOutputDeviceId;
        if ((!this.outputMediaAudio && isDefault) || sinkId === this.outputMediaAudio?.sinkId) return;
        const sink = isDefault ? this._sceneEl.audioListener.getInput() : this.audioDestination;
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].disconnect();
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].connect(sink);
        this.mixer[SourceType.AVATAR_AUDIO_SOURCE].connect(this.mixerAnalyser);

        this.mixer[SourceType.MEDIA_VIDEO].disconnect();
        this.mixer[SourceType.MEDIA_VIDEO].connect(sink);
        this.mixer[SourceType.MEDIA_VIDEO].connect(this.mixerAnalyser);

        this.mixer[SourceType.AUDIO_ZONE].disconnect();
        this.mixer[SourceType.AUDIO_ZONE].connect(sink);
        this.mixer[SourceType.AUDIO_ZONE].connect(this.mixerAnalyser);

        this.mixer[SourceType.MEDIA_AUDIO].disconnect();
        this.mixer[SourceType.MEDIA_AUDIO].connect(sink);
        this.mixer[SourceType.MEDIA_AUDIO].connect(this.mixerAnalyser);

        this.mixer[SourceType.SFX].disconnect();
        this.mixer[SourceType.SFX].connect(sink);
        this.mixer[SourceType.SFX].connect(this.mixerAnalyser);

        if (isDefault) {
            if (this.outputMediaAudio) {
                this.outputMediaAudio.pause();
                this.outputMediaAudio.srcObject = null;
                this.outputMediaAudio = null;
            }
        } else {
            // Swithing the audio sync is only supported in Chrome at the time of writing this.
            // It also seems to have some limitations and it only works on audio elements. We are piping all our media through the Audio Context
            // and that doesn't seem to work.
            // To workaround that we need to use a MediaStreamAudioDestinationNode that is set as the source of the audio element where we switch the sink.
            // This is very hacky but there don't seem to have any better alternatives at the time of writing this.
            // https://stackoverflow.com/a/67043782
            if (!this.outputMediaAudio) {
                this.outputMediaAudio = new Audio();
                this.outputMediaAudio.srcObject = this.audioDestination.stream;
            }
            if (this.outputMediaAudio.sinkId !== sinkId) {
                this.outputMediaAudio.setSinkId(sinkId).then(() => {
                    this.outputMediaAudio.play();
                });
            }
        }
        */
    }

    /**
     * Chrome and Safari will start Audio contexts in a "suspended" state.
     * A user interaction (touch/mouse event) is needed in order to resume the AudioContext.
     */
    _resumeAudioContext = () => {
        this.audioContext.resume();

        setTimeout(() => {
            if (this.audioContext.state === "running") {
                document.body.removeEventListener("touchend", this._resumeAudioContext, false);
                document.body.removeEventListener("mouseup", this._resumeAudioContext, false);
            }
        }, 0);
    };

    // Webkit mobile fix
    // https://stackoverflow.com/questions/10232908/is-there-a-way-to-detect-a-mobile-safari-audio-interruption-headphones-unplugg
    _safariMobileAudioInterruptionFix() {
        this.audioContext.onstatechange = () => {
            // console.log(`AudioContext state changed to ${this.audioContext.state}`);
            if (this.audioContext.state === "suspended") {
                // When you unplug the headphone or when the bluetooth headset disconnects on
                // iOS Safari or Chrome, the state changes to suspended.
                // Chrome Android doesn't go in suspended state for this case.
                document.body.addEventListener("touchend", this._resumeAudioContext, false);
                document.body.addEventListener("mouseup", this._resumeAudioContext, false);
                this.audioContextNeedsToBeResumed = true;
            } else if (this.audioContext.state === "running" && this.audioContextNeedsToBeResumed) {
                this.audioContextNeedsToBeResumed = false;
            }
        };
    }
}
