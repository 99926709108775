import { useEffect, useState } from "react";

const useTemplateAPI = (
    search = "",
    category = "",
    order = "-foreignkey_count",
    page = 1
) => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_MM_API_URL +
                        "templates/list-inside?page=" +
                        page +
                        "&search=" +
                        search +
                        "&ordering=" +
                        order +
                        "&category=" +
                        category
                );
                setRooms(await response.json());
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                setError(e);
            }
        })();
    }, [refreshIndex, page, category, search, order]);

    return [rooms, loading, error, refresh];
};

export default useTemplateAPI;
