import React, { useState, useEffect } from "react";
import HubChannel from "../utils/hub-channel";
import { connectToReticulum, denoisePresence, presenceEventsForHub } from "../utils/phoenix-utils";
import { setLocalClientID } from "../bit/systems/networking";
import { traverseMeshesAndAddShapes } from "../utils/physics-utils";
import { emitter } from "../utils/emitter";
import { toast } from "react-hot-toast";
import { listenForNetworkMessages } from "../utils/listen-for-network-messages";
import { updateSceneCopresentState, createHubChannelParams } from "../utils/hub-utils";
import registerNetworkSchemas from "../network-schemas";
import { Presence } from "phoenix";
import MessageDispatch from "../message-dispatch";
import { loadStoredRoomData } from "../utils/load-room-objects";
import nextTick from "../utils/next-tick";

const isMobile = AFRAME.utils.device.isMobile();
const isMobileVR = AFRAME.utils.device.isMobileVR();
let params = new URL(document.location).searchParams;
let editInParam = params.get("edit") !== null || params.get("edit_override") !== null;

function setupLobbyCamera() {
    // console.log("Setting up lobbycamera");
    const camera = document.getElementById("scene-preview-node");
    const previewCamera = document.getElementById("environment-scene").object3D.getObjectByName("scene-preview-camera");

    if (previewCamera) {
        camera.object3D.position.copy(previewCamera.position);
        camera.object3D.rotation.copy(previewCamera.rotation);
        camera.object3D.rotation.reorder("YXZ");
    } else {
        const cameraPos = camera.object3D.position;
        camera.object3D.position.set(cameraPos.x, 2.5, cameraPos.z);
    }

    camera.object3D.matrixNeedsUpdate = true;

    camera.removeAttribute("scene-preview-camera");
    camera.setAttribute("scene-preview-camera", "positionOnly: true; duration: 60");
}

export async function updateEnvironmentForHub(hub, classroom_scene_url) {
    document.querySelector(".a-canvas").classList.add("a-hidden");
    if (classroom_scene_url.startsWith("/")) {
        classroom_scene_url = "http://localhost:8000" + classroom_scene_url;
    }

    // console.log("Updateenvironmentforhub reached", classroom_scene_url);
    const sceneUrl = classroom_scene_url;

    const sceneErrorHandler = () => {
        // entryManager.exitScene();
    };

    const environmentScene = document.querySelector("#environment-scene");
    const sceneEl = document.querySelector("a-scene");

    const envSystem = sceneEl.systems["hubs-systems"].environmentSystem;

    const loadStart = performance.now();

    let environmentEl = null;

    if (environmentScene.childNodes.length === 0) {
        const environmentEl = document.createElement("a-entity");

        environmentEl.addEventListener(
            "model-loaded",
            () => {
                environmentEl.removeEventListener("model-error", sceneErrorHandler);

                // Show the canvas once the model has loaded
                envSystem.updateEnvironment(environmentEl);

                //TODO: check if the environment was made with spoke to determine if a shape should be added
                traverseMeshesAndAddShapes(environmentEl);

                document.querySelector(".a-canvas").classList.remove("a-hidden");

                sceneEl.addState("visible");
                // console.log("Done loading environment");
                setupLobbyCamera();
            },
            { once: true }
        );

        environmentEl.addEventListener("model-error", sceneErrorHandler, { once: true });

        environmentEl.setAttribute("gltf-model-plus", { src: sceneUrl, useCache: false, inflate: true });
        environmentScene.appendChild(environmentEl);
    } else {
        // Change environment
        environmentEl = environmentScene.childNodes[0];

        // Clear the three.js image cache and load the loading environment before switching to the new one.
        // THREE.Cache.clear();

        environmentEl.addEventListener(
            "model-loaded",
            () => {
                environmentEl.removeEventListener("model-error", sceneErrorHandler);

                // Show the canvas once the model has loaded
                envSystem.updateEnvironment(environmentEl);

                //TODO: check if the environment was made with spoke to determine if a shape should be added
                traverseMeshesAndAddShapes(environmentEl);

                document.querySelector(".a-canvas").classList.remove("a-hidden");

                sceneEl.addState("visible");
                // console.log("Done loading inside else statemtn");
                setupLobbyCamera();
            },
            { once: true }
        );

        environmentEl.setAttribute("gltf-model-plus", { src: sceneUrl, inflate: true });

        document.querySelector(".a-canvas").classList.remove("a-hidden");
        // console.log("else statement Done loading environment");
    }
}

const filterUsers = users => {
    return Object.entries(users)
        .filter(([id, user]) => user.metas.every(meta => meta.context.discord !== true))
        .reduce((acc, [id, user]) => {
            acc[id] = user;
            return acc;
        }, {});
};

function handleHubChannelJoined(entryManager, hubChannel, messageDispatch, data, room, events) {
    const scene = document.querySelector("a-scene");
    const isRejoin = NAF.connection.isConnected();

    if (isRejoin) {
        // Slight hack, to ensure correct presence state we need to re-send the entry event
        // on re-join. Ideally this would be updated into the channel socket state but this
        // would require significant changes to the hub channel events and socket management.
        if (scene.is("entered")) {
            hubChannel.sendEnteredEvent();
        }

        // Send complete sync on phoenix re-join.
        // TODO: We should be able to safely remove this completeSync now that
        //       NAF occupancy is driven from phoenix presence state.
        NAF.connection.entities.completeSync(null, true);
        return;
    }

    const hub = data.hubs[0];

    scene.addEventListener(
        "didConnectToNetworkedScene",
        () => {
            loadStoredRoomData(hub.hub_id);
        },
        { once: true }
    );

    scene.setAttribute("networked-scene", {
        room: hub.hub_id,
        serverURL: `wss://${hub.host}:${hub.port}`, // TODO: This is confusing because this is the dialog host and port.
        debug: false,
        adapter: "phoenix"
    });

    (async () => {
        while (!scene.components["networked-scene"] || !scene.components["networked-scene"].data) await nextTick();

        window.APP.hub = hub;
        window.APP.clientId = data.session_id;

        scene.emit("hub_updated", { hub });
        updateEnvironmentForHub(hub, room.scene.scene_glb);

        // Disconnect in case this is a re-entry
        APP.dialog.initialize({
            roomId: hub.hub_id,
            clientId: data.session_id
        });

        scene.addEventListener(
            "adapter-ready",
            ({ detail: adapter }) => {
                adapter.hubChannel = hubChannel;
                adapter.events = events;
                adapter.session_id = data.session_id;
            },
            { once: true }
        );
        scene.components["networked-scene"]
            .connect()
            .then(() => {
                scene.emit("didConnectToNetworkedScene");
            })
            .catch(connectError => {
                onConnectionError(entryManager, connectError);
            });
    })();
}

const setupHubsStuff = async (room, scene, entryManager, hubChannel, messageDispatch, events) => {
    if (!editInParam) {
        const socket = await connectToReticulum(false);

        socket.onClose(e => {
            if (e.code === 1000) {
                entryManager.exitScene();
            }
        });

        // Reticulum global channel
        APP.retChannel = socket.channel(`ret`, { hub_id: room.hub_id });

        APP.hubChannelParamsForPermsToken = permsToken => {
            return createHubChannelParams({
                profile: APP.store.state.profile,
                permsToken,
                isMobile,
                isMobileVR,
                isEmbed: false,
                hubInviteId: null,
                authToken: APP.store.state.credentials && APP.store.state.credentials.token
            });
        };

        const hubPhxChannel = socket.channel(`hub:${room.hub_id}`, APP.hubChannelParamsForPermsToken());
        hubChannel.channel = hubPhxChannel;
        hubChannel.presence = new Presence(hubPhxChannel);
        const { rawOnJoin, rawOnLeave } = denoisePresence(presenceEventsForHub(events));
        hubChannel.presence.onJoin(rawOnJoin);
        hubChannel.presence.onLeave(rawOnLeave);
        hubChannel.presence.onSync(() => {
            events.trigger(`hub:sync`, { presence: hubChannel.presence });
        });

        events.on(`hub:join`, ({ key, meta }) => {
            if (meta.context.discord) {
                // Pass for monitoring elsewhere
            } else {
                setTimeout(() => {
                    scene.emit("usercount_updated");
                }, 1000);
                scene.emit("presence_updated", {
                    sessionId: key,
                    profile: meta.profile,
                    hand_raised: meta.hand_raised,
                    typing: meta.typing
                });
            }
        });

        events.on(`hub:change`, ({ key, current }) => {
            if (current?.context?.discord) {
                // Pass for monitoring elsewhere
            } else {
                scene.emit("presence_updated", {
                    sessionId: key,
                    profile: current.profile,
                    hand_raised: current.hand_raised,
                    typing: current.typing
                });
            }
        });

        events.on(`hub:leave`, ({ key, meta }) => {
            if (meta.context.discord) {
                // Pass for monitoring elsewhere
            } else {
                setTimeout(() => {
                    scene.emit("usercount_updated");
                }, 1000);
            }
        });

        // We need to be able to wait for initial presence syncs across reconnects and socket migrations,
        // so we create this object in the outer scope and assign it a new promise on channel join.
        const presenceSync = {
            promise: null,
            resolve: null
        };

        events.on(`hub:sync`, () => {
            APP.hideHubPresenceEvents = false;
        });

        events.on(`hub:sync`, ({ presence }) => {
            updateSceneCopresentState(presence, scene);
        });

        listenForNetworkMessages(hubPhxChannel, events);

        hubPhxChannel
            .join()
            .receive("ok", async data => {
                setLocalClientID(data.session_id);
                APP.hideHubPresenceEvents = true;
                presenceSync.promise = new Promise(resolve => {
                    presenceSync.resolve = resolve;
                });

                events.on("hub:sync", () => {
                    presenceSync.resolve();
                });

                socket.params().session_id = data.session_id;
                socket.params().session_token = data.session_token;

                const permsToken = data.perms_token;
                hubChannel.setPermissionsFromToken(permsToken);

                await presenceSync.promise;
                handleHubChannelJoined(entryManager, hubChannel, messageDispatch, data, room, events);
            })
            .receive("error", res => {
                if (res.reason === "closed") {
                    entryManager.exitScene();
                } else if (res.reason === "oauth_required") {
                    entryManager.exitScene();
                } else if (res.reason === "join_denied") {
                    entryManager.exitScene();
                }

                console.error(res);
            });

        hubPhxChannel.on("message", ({ session_id, type, body, from }) => {
            console.log("Got incoming message...", body);
            const getAuthor = () => {
                const userInfo = hubChannel.presence.state[session_id];
                if (from) {
                    return from;
                } else if (userInfo) {
                    return userInfo.metas[0].profile.displayName;
                } else {
                    return "Mystery user";
                }
            };

            const name = getAuthor();
            const maySpawn = scene.is("entered");

            const incomingMessage = {
                name,
                type,
                body,
                maySpawn,
                sessionId: session_id,
                sent: session_id === socket.params().session_id
            };

            messageDispatch.receive(incomingMessage);
        });

        hubPhxChannel.on("mute", ({ session_id }) => {
            if (session_id === NAF.clientId) {
                APP.mediaDevicesManager.micEnabled = false;
            }
        });
        return socket;
    }
};

function handlePreviewJoined(entryManager, room) {
    setLocalClientID("preview-session");
    window.APP.hub = room;
    loadStoredRoomData(room.hub_id);
    updateEnvironmentForHub(room, room.scene.scene_glb);
}

export const useHubsNetworking = (room, scene, entryManager, previewInParams, editTemplateInParams) => {
    // Initialize

    const [finished, setFinished] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [presences, setPresences] = useState({});
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        window.APP.classroom = room;
        if (room && room.is_source_clone && editTemplateInParams && !finished) {
            // console.log("Initializing useHubsNetworking");
            const canvas = document.querySelector(".a-canvas");
            const events = emitter();
            document.title = room.title + " | MegaMinds";
            document.getElementById("Root").classList.add("three-root");
            scene.addState("loaded");
            canvas.classList.add("a-hidden");
            entryManager.init();

            const messageDispatch = new MessageDispatch(scene, entryManager);
            const hubChannel = new HubChannel(APP.store, room.hub_id);
            APP.hubChannel = hubChannel;
            setupHubsStuff(room, scene, entryManager, hubChannel, messageDispatch, events).then(data => {
                console.log("setupHubsStuff", data);
                setFinished(true);
                events.on(`hub:sync`, ({ presence }) => {
                    // console.log("Got presence sync");
                    setSessionId(data.params().session_id);
                    setPresences(presence.state);
                });
            });
        }

        if (room && (room.is_source_clone || previewInParams) && !room.locked_deny && !finished) {
            // Regular preview, skip networking etc.
            // console.log("Loaded preview normally");
            handlePreviewJoined(entryManager, room);
            setFinished(true);
            const canvas = document.querySelector(".a-canvas");
            document.title = room.title + " | MegaMinds";
            document.getElementById("Root").classList.add("three-root");
            scene.addState("loaded");
            canvas.classList.add("a-hidden");
            entryManager.init();
        }

        if (!previewInParams && room && !room.locked_deny && !room.is_source_clone && !finished) {
            // console.log("Initializing useHubsNetworking");
            const canvas = document.querySelector(".a-canvas");
            const events = emitter();
            document.title = room.title + " | MegaMinds";
            document.getElementById("Root").classList.add("three-root");
            scene.addState("loaded");
            canvas.classList.add("a-hidden");
            entryManager.init();

            if (!room.is_source_clone || !editInParam) {
                registerNetworkSchemas();
            }

            const messageDispatch = new MessageDispatch(scene, entryManager);
            const hubChannel = new HubChannel(APP.store, room.hub_id);
            APP.hubChannel = hubChannel;
            setupHubsStuff(room, scene, entryManager, hubChannel, messageDispatch, events).then(data => {
                setFinished(true);
                events.on(`hub:sync`, ({ presence }) => {
                    // console.log("Got presence sync");
                    setSessionId(data.params().session_id);
                    setPresences(presence.state);
                });
            });
        }
    }, [room, finished]);

    return [finished, presences, sessionId];
};
