import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import "../styles/global.css";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { useAssignment } from "../hooks/useAssignment";

import { toast } from "react-hot-toast";

export function AssignmentRoot(props) {
    const [isPosting, setIsPosting] = useState(false);
    const [name, setName] = useState(null);
    const [nameError, setError] = useState(false);
    const [roomCode, setRoomCode] = useState("");

    let params = new URL(document.location).searchParams;
    const newParams = useParams();
    console.log(newParams.code);
    let skipEntryInParam = params.get("skip_entry") !== null;
    console.log(params, skipEntryInParam);

    const [assignment, assignmentLoading, assignmentError, assignmentRefresh] = useAssignment(newParams.code);

    const recentRoom = JSON.parse(localStorage.getItem("recent_room"));

    console.log("Got assignment", assignment);

    const createClassroom = () => {
        if (!name) {
            setError(true);
            return;
        }
        setIsPosting(true);

        const new_classroom_props = {
            title: name,
            assignment_parent: assignment.id,
            description: assignment.instructions,
            guests_can_move: true,
            guests_can_create: true,
            guests_can_chat: true,
            guests_can_draw: true,
            guests_can_raise_hand: true,
            guests_can_react: true,
            guests_can_share_video: true,
            guests_can_share_microphone: true,
            guests_can_see_student_menu: true,
            guests_can_spawn_stickynotes: true
        };

        const payload = {
            assignment_parent: assignment.id,
            new_classroom_props: new_classroom_props
        };

        fetch(process.env.REACT_APP_MM_API_URL + "clone-assignment/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })
            .then(res => res.json())
            .then(data => {
                localStorage.setItem(
                    "recent_room",
                    JSON.stringify({
                        data: data,
                        name: name,
                        assignment_code: newParams.code
                    })
                );

                if (skipEntryInParam) {
                    window.location.replace(window.location.origin + "/" + data + "/?skip_entry");
                } else {
                    window.location.replace(window.location.origin + "/" + data);
                }
            })
            .catch(e => {
                console.error(e);
            });
    };

    if (!assignment && assignmentLoading) {
        return <p>Loading</p>;
    }

    if (assignmentError) {
        return (
            <Container className="d-flex justify-content-center h-100 bg-dark text-dark" fluid>
                <div
                    style={{ pointerEvents: "all", zIndex: 9999, height: "80px", maxWidth: "400px" }}
                    className="h-100 d-flex flex-column text-center justify-content-center align-items-center"
                >
                    <p className="text-white">This assignment either does not exist or is no longer available.</p>;
                </div>
            </Container>
        );
    }

    if (assignment.locked) {
        return <p>This distribution is closed.</p>;
    }

    return (
        <Container className="d-flex justify-content-center h-100 bg-dark text-dark" fluid>
            <div
                style={{ pointerEvents: "all", zIndex: 9999, height: "80px", maxWidth: "800px" }}
                className="h-100 d-flex flex-column justify-content-center align-items-center"
            >
                {recentRoom !== null && recentRoom.assignment_code  && recentRoom.assignment_code === newParams.code && (
                    <Alert className="">
                        <p className="fw-bold">We found a recent project on this device.</p>
                        <p>
                            Do you want to continue with the project named{" "}
                            <span className="fw-bold">{recentRoom.name}</span>?
                        </p>
                        <Button href={"/" + recentRoom.data} target="_blank" className="w-100 mt-3" variant="success">
                            {isPosting ? "Loading..." : "Continue"}
                        </Button>
                    </Alert>
                )}
                <Row className="bg-white rounded">
                    <Col xs={5} className="mt-3 mb-3">
                        {assignment.is_group ? <p>Create a group space</p> : <p>Create a new space</p>}
                        <Form.Control
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={assignment.is_group ? "Group Name" : "Your Name"}
                            type="text"
                        />
                        {nameError && (
                            <p className="fw-light text-danger mb-0 mt-2">
                                <small>You need to name your space</small>
                            </p>
                        )}
                        <Button
                            disabled={isPosting}
                            className="w-100 mt-3"
                            onClick={() => createClassroom()}
                            variant="success"
                        >
                            {isPosting ? "Loading..." : "Create"}
                        </Button>
                    </Col>
                    <Col xs={2} className="d-flex justify-content-center align-items-center">
                        <p>Or</p>
                    </Col>
                    <Col xs={5} className="mt-3 mb-3">
                        <p>Continue in existing space</p>
                        <Form.Control
                            value={roomCode}
                            onChange={e => setRoomCode(e.target.value)}
                            placeholder="Room Code"
                            type="text"
                        />
                        <small className="fw-light text-muted">You can get the room code from your teacher</small>
                        <Button href={"/" + roomCode} target="_blank" className="w-100 mt-3" variant="success">
                            {isPosting ? "Loading..." : "Continue"}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    {assignment.is_group && (
                        <Card.Body>
                            <p>Or join an existing group...</p>
                            {assignment.classroom_set?.length === 0 && (
                                <>
                                    <p>No group spaces yet.</p>
                                    <Button variant="purple-dark" className="mt-2" size="sm" onClick={props.refresh}>
                                        <small>Refresh Spaces</small>
                                    </Button>
                                </>
                            )}
                            {assignment.classroom_set?.length > 0 && (
                                <>
                                    <ul>
                                        {assignment.classroom_set?.map(room => (
                                            <li key={room.hub_id}>
                                                <a href={"/" + room.hub_id}> {room.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                    <Button variant="purple-dark" className="mt-5" size="sm" onClick={props.refresh}>
                                        <small>Refresh Spaces</small>
                                    </Button>
                                </>
                            )}
                        </Card.Body>
                    )}
                </Row>
            </div>
        </Container>
    );
}
