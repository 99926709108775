import React, { useState, useEffect } from "react";
import "react-tooltip/dist/react-tooltip.css";

import LoadingScreen from "./LoadingScreen";

import PreviewInterface from "./PreviewInterface";
import Interface from "./Interface";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { setupIdleDetection } from "../utils/idle-detection";

import { useAuth0 } from "@auth0/auth0-react";
import { setupWebsocketListeners } from "../utils/websocket-root";
import { useHubsNetworking } from "./setupHubsNetworking.js";
import { renderAsEntity } from "../utils/jsx-entity";
import { VideoMenuPrefab } from "../prefabs/video-menu";
import { useRoom } from "./hooks/useRoom";
import useAvatars from "./hooks/useAvatars";
import useLiveKitToken from "./hooks/useLiveKitToken";
import useAudioSystem from "./hooks/useAudioSystem";

import CommandHelper from "./editor/command-helpers";
import ChatHelper from "../utils/chat-helper";
import ObjectHelper from "../utils/ObjectHelper";
import UserHelper from "../utils/UserHelper";
import { InitialClassroomSetupFlow } from "./classroom/create/InitialClassroomSetupFlow";

import { DIALOG_CONNECTION_ERROR_FATAL, DIALOG_CONNECTION_CONNECTED } from "../livekit-adapter";

let params = new URL(document.location).searchParams;
let editTemplateInParams = params.get("editTemplate") !== null;

function Root() {
    const scene = document.querySelector("a-scene");
    APP.scene = scene;

    const environmentScene = document.querySelector("#environment-scene");
    const entryManager = window.APP.entryManager;

    // Step 1: Establish if user is authenitcated with Auth0 and get the classroom info
    const { isLoading, isAuthenticated, user, getAccessTokenSilently, loginWithPopup } = useAuth0();

    const [room, roomLoading, roomError, roomRefresh] = useRoom(isLoading, isAuthenticated);
    const [isAuthed, setIsAuthed] = useState(false);
    const [kicked, setKicked] = useState(false);
    const [disconnected, setDisconnected] = useState(false);
    const [environmentLoaded, setEnvironmentLoaded] = useState(false);
    const [websocket, setWebsocket] = useState(false);
    const [hubsNetworkingFinished, presences, sessionId] = useHubsNetworking(
        room,
        scene,
        entryManager,
        false,
        editTemplateInParams
    );
    const [helpersFinished, setHelpersFinished] = useState(false);
    const [avatars, isLoadingAvatars, isError] = useAvatars();
    const [permissionSystemLoaded, setPermissionSystemLoaded] = useState(false);

    const [audioAndMicLoaded, audioStatus] = useAudioSystem(scene, permissionSystemLoaded);

    const [liveKitToken, tokenLoading, tokenError] = useLiveKitToken(hubsNetworkingFinished);

    const [liveKitStatus, setLiveKitStatus] = useState("disconnected");

    useEffect(() => {
        if (liveKitToken && liveKitStatus !== "connected" && helpersFinished) {
            APP.dialog.connectToRoom(liveKitToken).then(() => {
                setLiveKitStatus("connected");
            });
        }
    }, [liveKitToken, liveKitStatus, helpersFinished]);

    useEffect(() => {
        renderAsEntity(APP.world, VideoMenuPrefab());
        setupIdleDetection();

        APP.dialog.on(DIALOG_CONNECTION_CONNECTED, () => {
            scene.emit("didConnectToDialog");
        });

        APP.dialog.on(DIALOG_CONNECTION_ERROR_FATAL, () => {
            // TODO: Change the wording of the connect error to match dialog connection error
            // TODO: Tell the user that dialog is broken, but don't completely end the experience
            APP.entryManager.exitScene();
        });

        window.APP.scene.addEventListener("kicked", () => {
            console.log("You got kicked");
            setKicked(true);
        });

        window.APP.scene.addEventListener("locked_room", () => {
            console.log("Room was locked by a teacher");
            setKicked(true);
        });

        environmentScene.addEventListener("model-loaded", ({ detail: { model } }) => {
            console.log("Environment scene has loaded");
            setEnvironmentLoaded(true);
            scene.emit("environment-scene-loaded", model);
        });
    }, []);

    useEffect(() => {
        // Check if user has valid avatar

        if (avatars && !isLoadingAvatars) {
            const avatarId = APP.store.state.profile.avatarId;
            const aviMatch = avatars.find(avi => avi.id === avatarId);
            if (!aviMatch) {
                // User has invalid avatar
                document.location = `/entry?destination=${encodeURIComponent(document.location.toString())}`;
            }
        }
    }, [avatars, isLoadingAvatars]);

    useEffect(() => {
        if (!helpersFinished && websocket && hubsNetworkingFinished && room) {
            window.APP.commandHelper = new CommandHelper();
            window.APP.chatHelper = new ChatHelper(websocket);
            window.APP.objectHelper = new ObjectHelper(websocket, room);
            window.APP.userHelper = new UserHelper(websocket);
            window.APP.objectHelper.get_permissions();

            window.APP.scene.addEventListener("finished_permissions_sync", () => {
                console.log("Got initial sync of permissions");
                setPermissionSystemLoaded(true);
            });
            setHelpersFinished(true);
        }
    }, [websocket, hubsNetworkingFinished, room]);

    useEffect(() => {
        if (room && params.get("edit_override") === null) {
            if (isAuthenticated) {
                console.log("Connecting to websocket as authenticated user...");
                getAccessTokenSilently()
                    .then(token => {
                        const socket = new WebSocket(
                            process.env.REACT_APP_WEBSOCKET_ENDPOINT +
                                room.hub_id +
                                "/?token=" +
                                token +
                                "&user_name=" +
                                APP.store.state.profile.displayName
                        );
                        socket.addEventListener("open", resp => {
                            setWebsocket(socket);
                            setIsAuthed(true);
                        });
                        setupWebsocketListeners(socket, token, () => setDisconnected(true));
                    })
                    .catch(e => {
                        console.error("Error connecting as authenticated user. Session expired?", e);
                    });
            } else {
                try {
                    // Try forcing auth0 to log in
                    console.log("Forcing websockets as authenticated user...");
                    getAccessTokenSilently()
                        .then(token => {
                            const socket = new WebSocket(
                                process.env.REACT_APP_WEBSOCKET_ENDPOINT +
                                    room.hub_id +
                                    "/?token=" +
                                    token +
                                    "&user_name=" +
                                    APP.store.state.profile.displayName
                            );
                            socket.addEventListener("open", resp => {
                                setWebsocket(socket);
                                setIsAuthed(true);
                            });
                            setupWebsocketListeners(socket, token, () => setDisconnected(true));
                        })
                        .catch(e => {
                            console.error("Error connecting as authenticated user. Session expired?", e);
                            const socket = new WebSocket(
                                process.env.REACT_APP_WEBSOCKET_ENDPOINT +
                                    room.hub_id +
                                    "/?user_name=" +
                                    APP.store.state.profile.displayName
                            );
                            socket.addEventListener("open", resp => {
                                setWebsocket(socket);
                            });
                            setupWebsocketListeners(socket, null, () => setDisconnected(true));
                        });
                } catch (e) {
                    console.log("Could not force, Connecting to websocket as guest...");
                    const socket = new WebSocket(
                        process.env.REACT_APP_WEBSOCKET_ENDPOINT +
                            room.hub_id +
                            "/?user_name=" +
                            APP.store.state.profile.displayName
                    );
                    socket.addEventListener("open", resp => {
                        setWebsocket(socket);
                    });
                    setupWebsocketListeners(socket, null, () => setDisconnected(true));
                }
            }
        } else {
            // Room is not loaded yet
        }
    }, [room]);

    if (roomError) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <Col
                    className="d-flex flex-column justify-content-center text-center bg-white rounded"
                    style={{ maxWidth: "150px" }}
                >
                    <div className="p-2">We could not load this space.</div>
                </Col>
            </div>
        );
    }

    if (!room) {
        return <LoadingScreen message="Loading..."></LoadingScreen>;
    }

    console.log("FROM AUTH LOCKED BEFORE", room);
    if (room && room.locked_deny && !isAuthed) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <Col
                    className="d-flex flex-column justify-content-center text-center bg-white rounded"
                    style={{ maxWidth: "150px" }}
                >
                    <div className="p-2">
                        <p>This Lesson is currently locked.</p>
                    </div>
                </Col>
            </div>
        );
    }

    if (!audioAndMicLoaded) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <Col
                    className="d-flex flex-column justify-content-center text-center bg-white rounded"
                    style={{ maxWidth: "150px" }}
                >
                    <div className="p-2">
                        <p className="mb-0">Requesting Microphone and Webcam Permissions...</p>
                    </div>
                </Col>
            </div>
        );
    }

    if (disconnected && !kicked) {
        return (
            <div className="w-100 h-100 bg-white all-pointer-events">
                <div className="position-absolute top-50 start-50 translate-middle max-z">
                    <Col
                        className="d-flex flex-column justify-content-center text-center bg-white rounded"
                        style={{ maxWidth: "150px" }}
                    >
                        <div className="p-2">You were disconnected from the server.</div>
                        <div className="p-2">
                            This may be due to loss of network connection or scheduled server restarts or maintainance.
                        </div>
                        <Button variant="purple-dark" onClick={() => location.reload()} className="p-2">
                            Reload
                        </Button>
                    </Col>
                </div>
            </div>
        );
    }

    if (kicked) {
        return (
            <div className="w-100 h-100 bg-white">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <Col
                        className="d-flex flex-column justify-content-center text-center bg-white rounded"
                        style={{ maxWidth: "150px" }}
                    >
                        <div className="p-2">You were disconnected from the server.</div>
                    </Col>
                </div>
            </div>
        );
    }

    if (room.is_source_clone && params.get("editTemplate") === null) {
        if (environmentLoaded) {
            return <PreviewInterface scene={scene} room={room} entryManager={entryManager} />;
        } else {
            <LoadingScreen message="Loading Preview..." />;
        }
    }


    console.log(websocket, hubsNetworkingFinished);
    if (!websocket || !hubsNetworkingFinished || !helpersFinished || !environmentLoaded || !permissionSystemLoaded) {
        return (
            <LoadingScreen
                message={
                    !websocket || !hubsNetworkingFinished
                        ? "Connecting"
                        : !environmentLoaded
                        ? "Loading environment..."
                        : !helpersFinished
                        ? "Setting up helpers"
                        : !audioAndMicLoaded
                        ? "Setting up audio and microphone..."
                        : !permissionSystemLoaded
                        ? "Setting up permission system..."
                        : "Done!"
                }
            ></LoadingScreen>
        );
    }

    if (room.is_fresh && isAuthed) {
        console.log("IS FRESH");
        return <InitialClassroomSetupFlow onEnterRoom={() => roomRefresh()} />;
    }

    if (room.is_fresh && !isAuthed) {
        console.log("IS FRESH");
        return (
            <div className="w-100 h-100 bg-white all-pointer-events">
                <div className="position-absolute top-50 start-50 translate-middle max-z">
                    <Col
                        className="d-flex flex-column justify-content-center text-center bg-white rounded"
                        style={{ maxWidth: "350px" }}
                    >
                        <div className="p-2 fw-bold">This Lesson is not set up yet.</div>
                        <div className="p-2">
                            Please let the Lesson owner know that they need to set up the Lesson by opening it before
                            inviting others.
                        </div>
                        <div className="p-2 text-muted">
                            If you are the owner of the Lesson and you are seeing this message, it most likely means you
                            are not signed in to the dashboard, or you are using a private tab.
                        </div>
                        <div className="p-2 text-muted">
                            If you beleive this is an error, please get in touch at contact@gomegaminds.com
                        </div>
                        {process.env.REACT_APP_MM_IS_ENV !== "prod" && (
                            <>
                                <p>You look like a developer using a mismatched auth. Try logging in again?</p>
                                <Button
                                    variant="purple-dark"
                                    onClick={() => {
                                        loginWithPopup().then(() => {
                                            location.reload();
                                        });
                                    }}
                                >
                                    Login
                                </Button>
                            </>
                        )}
                    </Col>
                </div>
            </div>
        );
    }

    return (
        <Interface
            room={room}
            audioStatus={audioStatus}
            presences={presences}
            sessionId={sessionId}
            scene={scene}
            entryManager={entryManager}
        />
    );
}

export default Root;
